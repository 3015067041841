import * as React from "react"
import { GlobalStyle } from "../components/globalStyle"
import Footer from "../components/footer"
import Seo from "../components/seo"
import Navbar from "../components/navbar2"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <Navbar />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <GlobalStyle />
    <Footer />
  </>
)

export default NotFoundPage
